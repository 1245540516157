import logo1 from './1.png';
import logo2 from './2.png';
import logo3 from './3.png';
import logo4 from './4.png';
import logo5 from './5.png';
import logo6 from './6.png';
import logo7 from './7.png';
import tFlag from './Flag_of_Turkey.png'
import eFlag from './Flag_of_the_United_Kingdom.png'
import React from 'react';

import './App.css';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {lan: 'turkish'}
  }
  
  render(){

  return (
    <div className="App">
      <header className="App-header">
      <div className="imageContainer">        
        <div className='imageDiv' onClick={() => this.setState({lan : 'turkish'})}>
        <img className='flag' style={this.state.lan == 'english' ? {opacity:0.6} : null} src={tFlag} alt="turkish" />    
        </div>
        <div className='imageDiv' onClick={() => this.setState({lan : 'english'})}>
        <img  className='flag' style={this.state.lan == 'english' ? null : {opacity:0.6}} src={eFlag} alt="english" />     
    </div>
    </div>
        <img src={this.state.lan == 'turkish' ? logo6 : logo7} className="App-logo" alt="logo" />
        <img src={logo1} className="App-logo" alt="logo" />
        <img src={logo2} className="App-logo" alt="logo" />
        <img src={logo3} className="App-logo" alt="logo" />
        <img src={logo4} className="App-logo" alt="logo" />
        <img src={logo5} className="App-logo" alt="logo" />

      </header>
    </div>
  );
}
}

export default App;
